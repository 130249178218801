import { PerViewport } from '@hubcms/domain-styling';

export function getIntroHidden(hasIntro?: boolean | PerViewport<boolean>) {
  if (hasIntro === undefined) {
    return true;
  }
  if (typeof hasIntro === 'boolean') {
    return !hasIntro;
  }
  return {
    xs: !hasIntro.xs,
    sm: !hasIntro.sm,
    md: !hasIntro.md,
    lg: !hasIntro.lg,
    xl: !hasIntro.xl,
  };
}
